import React from 'react';

import Seo from '../components/seo/Seo';
import LegalView from '../views/LegalView';

export default function Legal() {
  return (
    <>
      <Seo
        currentTitle="Mentions légales"
        currentDescription="Les mentions légales servent à assurer une certaine transparence et rassurer les internautes sur l'identité des informations."
      />
      <LegalView />
    </>
  );
};
