import React from 'react';
import { Link, Button } from 'gatsby-theme-material-ui';

import { Box } from '@mui/material';

import { Reveal } from '../hooks/reveal';
import { useStyles } from './legalView.styles';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { Subtitle, TextContent } from '../components/utils/utils';

export default function LegalView() {
  const { author, siteName, company } = useSiteMetadata();

  return (
    <Box component="section" className="legal">
      <Reveal>
        <Box mb={5}>
          <Subtitle component="h1" sx={{ ...useStyles.title }}>
            Mentions légales
          </Subtitle>
          <TextContent paragraph={true}>
            <strong>{siteName} est édité et exploité par</strong>
            <br />
            {company.title} - {company.fribourg.address}.
            <br />
            Téléphone : {company.fribourg.tel}
            <br />
            E-mail : {company.email}
          </TextContent>
          <TextContent paragraph={true}>
            <strong>Représentants légaux</strong>
            <br />
            {company.manager}.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Box mb={5}>
          <Subtitle component="h2" sx={{ ...useStyles.title }}>
            Directive de confidentialité Internet {author}
          </Subtitle>
          <TextContent paragraph={true}>
            {author} respecte et préserve l’intégrité des informations personnelles des visiteurs de son site web. Nous utilisons les données recueilles à votre propos pour vous présenter les informations que vous avez demandées.
            <br />
            Nous nous en servons également pour améliorer notre service. Pour ce faire nous traitons vos informations avec la discrétion qui s’impose. Les informations personnelles recueillies ne sont pas divulguées à des tiers. Vos informations sont traitées dans le respect de la législation applicable en matière de protection des données personnelles. Leur enregistrement et leur traitement peuvent être effectués dans ou en dehors de l’Union Européenne, partout dans le monde.
            <br />
            Vous êtes en droit de vérifier les informations que nous détenons à votre propos et de les faire corriger si nécessaire. Vous avez également le droit de nous demander de ne pas faire usage de ces informations. Nous mettrons toute en œuvre, dans la mesure du raisonnable pour répondre à votre demande. Veuillez noter que certaines dispositions légales peuvent nous empêcher de fournir certaines informations.
            <br />
            Conformément à la loi fédérale du 19 juin 1992 sur la protection des données, (LPD), {author} s’engage à protéger vos données personnelles lors de leur traitement. Vous pouvez visiter la plupart de nos pages web sans avoir à nous communiquer la moindre information personnelle. Des données ne sont requises que si vous sollicitez certains services. Ces dispositions sur la protection des données vous renseignent sur la saisie et l’exploitation de vos données dans ce type de situation.
            <br />
            Nous vous demandons toujours de manière explicite les données nécessaires à l’identification de votre personne (données personnelles) ou à la connexion à notre site. Cette information nous est indispensable lors de toute procédure d’enregistrement.
          </TextContent>
          <TextContent paragraph={true}>
            <strong>L’exploitation des données</strong>
            <br />
            Vos données sont utilisées pour vous fournir les services que vous sollicitez. {author} ne divulguera vos données personnelles à des tiers que dans la mesure où cela s’impose dans le cadre des prescriptions légales particulières à caractère obligatoire.
          </TextContent>
          <TextContent paragraph={true}>
            <strong>Accès à vos données personnelles</strong>
            <br />
            Nous mettons à votre disposition vos données personnelles enregistrées chez nous. Sur votre demande écrite vous pouvez consulter vos données sauvegardées chez nous et nous les modifions volontiers. Nous respectons ainsi votre droit d’accès (art.8 LPD).
          </TextContent>
          <TextContent paragraph={true}>
            <strong>Sécurité de vos données personnelles</strong>
            <br />
            Conformément aux exigences légales, {author} veille rigoureusement à la sécurité de vos données personnelles (art.7 LPD). Nous avons recours à une série de technologies et de procédures relatives à la sécurité pour protéger vos données personnelles contre tout accès interdit, tout abus ou toute publication non autorisée.
          </TextContent>
          <TextContent>
            <strong>Contact avec {author}</strong>
            <br />
            Si vous avez des questions ou des réclamations à formuler à l’égard de notre respect de la présente politique de confidentialité, ou si vous désirez nous transmettre des recommandations ou des commentaires sur la manière d’en améliorer la qualité, veuillez nous contacter <Link
              underline="hover"
              to={`mailto:${company.email}?subject=Réclamation politique de confidentialité - ${author}`}
            >
              en cliquant ici
            </Link>.
          </TextContent>
        </Box>
      </Reveal>
      <Reveal>
        <Button
          to="/"
          size="large"
          variant="contained"
        >
          Retour à l'accueil
        </Button>
      </Reveal>
    </Box>
  );
};